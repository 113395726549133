<script setup>
import { computed } from 'vue';

const props = defineProps({
    icon: {
        type: String,
    },
    orientation: {
        type: String,
        default: 'left',
    }
});

const typeClass = computed(() => {
    switch (props.type) {
        case 'right':
            return 'ms-1';
        default:
            return 'me-1';
    }
});

</script>

<template>
    <i class="far" :class="[ props.icon, typeClass ]"></i>
</template>
